import Skeleton from 'components/shared/Skeleton';
import React from 'react';

function AnswerRowSkeleton() {
  return (
    <tr>
      <td>{}</td>
      <td><Skeleton classes="button" style={{ width: '2rem' }} /></td>
      <td><Skeleton classes="text" /></td>
      <td><Skeleton classes="text" /></td>
      <td><Skeleton classes="text" /></td>
      <td>
        <Skeleton classes="button me-2" style={{ width: '2rem' }} />
        <Skeleton classes="button me-2" style={{ width: '2rem' }} />
        <Skeleton classes="button" />
      </td>
    </tr>
  );
}

export default AnswerRowSkeleton;
