import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MARK_NOTIFICATION_AS_READ, MARK_NOTIFICATION_AS_UNREAD, UPDATE_COMMENT_FLAG } from 'store/facilitatorDashboard/actions';
import { CARD_TYPES } from 'store/facilitatorDashboard/reducer';
import { Button } from 'react-bootstrap';
import ParticipantInfoPopover from 'components/group_memberships/ParticipantInfoPopover';
import SanyasIcon from 'components/shared/SanyasIcon';
import Confirmation from 'components/shared/Modal/Confirmation';
import MultilineText from 'components/shared/MultilineText';
import NotificationDot from 'components/shared/NotificationDot';
import CommentCardDropdown from './CommentCardDropdown';
import EditCommentCard from './EditCommentCard';
import PostFlag from './PostFlag';
import Reply from './Reply';
import MarkAsReadButton from './MarkAsReadButton';
import ParticipantLink from './ParticipantLink';
import SubtitleDate from './SubtitleDate';
import './styles.scss';

function CommentCard({ commentId, isReply, isJournalComment, answerId }) {
  const dispatch = useDispatch();
  const params = useParams();
  const currentUser = useSelector((state) => state.currentUser);
  const [showReplyBox, setShowReplyBox] = useState(false);
  const [showRemoveFlag, setShowRemoveFlag] = useState(false);
  const [editable, setIsEditable] = useState(false);
  const { assignedFacilitatorId } = useSelector((state) => state.facilitatorDashboard);
  const comment = useSelector((state) => state.facilitatorDashboard.commentsById[commentId]);
  const isAssignedFacilitationPoint = currentUser?.id === assignedFacilitatorId;

  const toggleReplyBox = useCallback(() => {
    if (isJournalComment) {
      // Scroll to the main Comment at the bottom of the page when replying to a journal comment
      window.scrollTo(0, document.body.scrollHeight);
      return;
    }

    setShowReplyBox(!showReplyBox);
  }, [isJournalComment, showReplyBox]);

  const saveFlag = useCallback(() => {
    setShowRemoveFlag(false);
    dispatch(UPDATE_COMMENT_FLAG.request({ id: commentId, flag: null }));
  }, [commentId, dispatch]);

  const markComment = useCallback(() => {
    if (comment.notificationCleared) {
      dispatch(MARK_NOTIFICATION_AS_UNREAD.request({ id: comment.notificationId, subGroupId: comment.subGroupId }, { cardType: CARD_TYPES.COMMENT_CARD, priority: comment.notificationPriority, gmId: comment.groupMembershipId }));
    } else {
      dispatch(MARK_NOTIFICATION_AS_READ.request({ id: comment.notificationId, subGroupId: comment.subGroupId }, { cardType: CARD_TYPES.COMMENT_CARD, priority: comment.notificationPriority, gmId: comment.groupMembershipId }));
    }
    document.activeElement.blur();
  }, [comment, dispatch]);

  if (!comment) return null;

  return (
    <div id={`comment-${comment.id}`} className={`comment-card mb-3 ${isReply ? 'col-md-10 offset-2' : 'w-100'}`}>
      {editable
        ? (
          <div className="d-flex flex-column align-items-end">
            <EditCommentCard commentId={comment.id} isReply setIsEditable={setIsEditable} comment={comment} discussionTaskId={params.taskId} subGroupId={params.subGroupId} />
          </div>
        ) : (
          <>
            <div className={`card shadow-sm rounded px-4 ${comment.isFacilitatorComment ? 'card-facilitator' : 'card-post'}`}>
              <div className="d-flex align-items-center justify-content-between position-relative my-2" style={{ minHeight: 28 }}>
                {(!comment.notificationCleared && !comment.isFacilitatorComment) && (
                  <div className="position-absolute top-50 start-0 translate-middle" style={{ paddingRight: '1.375rem' }}>
                    <NotificationDot age={comment.notificationPriority} primary={comment.taskType === 'SubGroupDiscussionTask'} />
                  </div>
                )}

                <div className="d-flex align-items-baseline fw-semibold">
                  {comment.isFacilitatorComment && (
                    <span className="text-accent-purple">
                      <SanyasIcon name="hummingbird" className="me-1" />
                      {comment.author}
                    </span>
                  )}
                  {!comment.isFacilitatorComment && (
                    <ParticipantLink
                      groupId={params.id}
                      groupMembershipId={comment.groupMembershipId}
                      participantName={isJournalComment ? comment.fullName : comment.author}
                    />
                  )}
                  <SubtitleDate className="ms-2" date={comment.createdAt} />
                  {(!comment.isFacilitatorComment && !isJournalComment) && (
                    <ParticipantInfoPopover participantId={comment.groupMembershipId} displayName={comment.author} className="ms-3" />
                  )}
                </div>

                {!comment.isFacilitatorComment && (
                  <div>
                    {isAssignedFacilitationPoint && (
                      <MarkAsReadButton post={comment} markAsRead={markComment} />
                    )}

                    {comment.flag && (
                      <PostFlag
                        flag={comment.flag}
                        as={Button}
                        disabled={!isAssignedFacilitationPoint}
                        onClick={() => { setShowRemoveFlag(true); document.activeElement.blur(); }}
                        variant="link"
                      />
                    )}
                  </div>
                )}
              </div>

              <MultilineText text={comment.comment} className="mb-0 py-1" />

              <div className="d-flex justify-content-end my-1">
                {!isReply && (
                  <Button
                    disabled={!isAssignedFacilitationPoint}
                    onClick={toggleReplyBox}
                    variant="plain"
                    size="sm"
                    className="me-1 px-1 fw-semibold"
                    style={{ '--bs-btn-color': comment.isFacilitatorComment ? 'var(--bs-sn-clr-purple)' : null }}
                  >
                    <SanyasIcon name="chat" className="me-1" />
                    Reply
                  </Button>
                )}
                <CommentCardDropdown isJournalComment={isJournalComment} disabled={!isAssignedFacilitationPoint} commentId={comment.id} setIsEditable={setIsEditable} />
              </div>
            </div>

            {!isJournalComment && (
              <div className="col-10 offset-2 mt-2">
                {showReplyBox && <Reply onClose={() => setShowReplyBox(false)} answerId={answerId} comment={comment} isJournal={isJournalComment} />}
              </div>
            )}
          </>
        )}

      <Confirmation
        title="Remove flag?"
        open={showRemoveFlag}
        close={() => setShowRemoveFlag(false)}
        text="Are you sure you want to remove the flag?"
        confirmText="Remove"
        confirmAction={saveFlag}
        cancelText={null}
      />
    </div>
  );
}

CommentCard.defaultProps = {
  isReply: false,
  isJournalComment: undefined,
  answerId: undefined,
};

CommentCard.propTypes = {
  commentId: PropTypes.number.isRequired,
  isReply: PropTypes.bool,
  isJournalComment: PropTypes.bool,
  answerId: PropTypes.number,
};

export default CommentCard;
