import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { CLOSE_SOMETHING_WENT_WRONG, GET_CURRENT_USER, GET_CURRENT_USER_NOTIFICATIONS, SHOW_SOMETHING_WENT_WRONG } from './actions';

import bulkImport from './bulkImport/reducer';
import courseTypes from './courseTypes/reducer';
import courses from './courses/reducer';
import emailTemplates from './emailTemplates/reducer';
import emailTransmissionReport from './emailTransmissionReport/reducer';
import facilitatorDashboard from './facilitatorDashboard/reducer';
import facilitatorReport from './facilitatorReport/reducer';
import flashNotifications from './flashNotifications/reducer';
import groupCreate from './groupCreate/reducer';
import groupMemberships from './groupMemberships/reducer';
import groupReports from './groupReports/reducer';
import groupShow from './groupShow/reducer';
import harmPreventionReport from './harmPreventionReport/reducer';
import healthAuthorities from './healthAuthorities/reducer';
import indigenousWelcomeLetters from './indigenousWelcomeLetters/reducer';
import messages from './messages/reducer';
import organizations from './organizations/reducer';
import participantShow from './participantShow/reducer';
import paymentReport from './paymentReport/reducer';
import platformClosure from './platformClosure/reducer';
import ptqReport from './ptqReport/reducer';
import responsesReport from './responsesReport/reducer';
import superFacilitatorReport from './superFacilitatorReport/reducer';
import user from './user/reducer';
import vouchers from './vouchers/reducer';

const rootReducers = {
  somethingWentWrong(state = false, action) {
    switch (action.type) {
      case SHOW_SOMETHING_WENT_WRONG.SYNC: return true;
      case CLOSE_SOMETHING_WENT_WRONG.SYNC: return false;
      default:
        return state;
    }
  },
  currentUser(state = null, action) {
    switch (action.type) {
      case GET_CURRENT_USER.SUCCESS:
        if (action.meta.cached) return state;
        return action.payload;
      case GET_CURRENT_USER_NOTIFICATIONS.SUCCESS:
        return {
          ...state,
          notifications: {
            discussionBoardNotifications: action.payload.discussionBoardNotifications.length,
            journalNotifications: action.payload.journalNotifications.length,
          },
        };

      default:
        return state;
    }
  },
};

export default (history) => combineReducers({
  router: connectRouter(history),
  ...rootReducers,
  bulkImport,
  courseTypes,
  courses,
  emailTemplates,
  emailTransmissionReport,
  facilitatorDashboard,
  facilitatorReport,
  flashNotifications,
  groupCreate,
  groupMemberships,
  groupReports,
  groupShow,
  harmPreventionReport,
  healthAuthorities,
  indigenousWelcomeLetters,
  messages,
  organizations,
  participantShow,
  paymentReport,
  platformClosure,
  ptqReport,
  responsesReport,
  superFacilitatorReport,
  user,
  vouchers,
});
