import React, { useMemo, useState } from 'react';
import _uniqBy from 'lodash/uniqBy';
import _orderBy from 'lodash/orderBy';
import { LEGACY_APP_DOMAIN } from 'config';
import { ACTION_STATUSES } from 'lib/constants';
import { GroupMembershipPropType } from 'lib/propTypes';
import { formatSeconds } from 'lib/utils';
import { Button } from 'react-bootstrap';
import SanyasIcon from 'components/shared/SanyasIcon';
import TaskLogItem from 'components/users/TaskLogItem';

function CourseProgress({ gm }) {
  const [showFullLog, setShowFullLog] = useState(false);

  const completedUserTasks = useMemo(() => gm?.userTasks?.filter((task) => task.completedAt) || [], [gm]);
  const totalActiveTime = useMemo(
    () => completedUserTasks.reduce(
      (acc, task) => acc + task.activeTimeBeforeCompletion + task.activeTimeAfterCompletion,
      0,
    ),
    [completedUserTasks],
  );

  const orderedCompletedUserTasks = _orderBy(completedUserTasks, ['completedAt'], ['desc']);
  const userTaskSections = _uniqBy(gm?.userTasks?.map(({ section }) => section), 'id');
  const sliceSize = Math.min(Math.ceil((completedUserTasks?.length || 0) / 5), 5); // show 20% of or 5 (whichever is smaller) completed tasks

  if (![...ACTION_STATUSES, 'completed'].includes(gm?.completion) && !gm?.percentageDoneCourse && !gm?.userTasks.length) return null;

  return (
    <>
      <h2>Course progress</h2>

      <div className="d-flex overflow-auto mb-4 pb-1 text-small">
        {userTaskSections.map((section) => {
          const sectionTasksCount = gm.userTasks.filter((task) => task.section.id === section.id).length;
          const completedSectionTasksCount = completedUserTasks.filter((task) => task.section.id === section.id).length;

          const bgColor = completedSectionTasksCount > 0 ? 'bg-success' : 'bg-secondary';
          let bgOpacity = ' bg-opacity-25';
          if (completedSectionTasksCount === sectionTasksCount) {
            bgOpacity = ' bg-opacity-75';
          }

          return (
            <div key={section.id} className={`d-flex flex-column flex-shrink-0 justify-content-center rounded me-1 ${bgColor}${bgOpacity}`} title={section.name}>
              <p className="my-0 px-2 text-center bg-white bg-opacity-50">
                Module
                {' '}
                {section.position}
              </p>

              <p className="m-0 p-1 bg-white bg-opacity-75">
                {gm?.userTasks.filter((task) => task.section.id === section.id).map((task) => (
                  <a
                    key={task.id}
                    href={`${LEGACY_APP_DOMAIN}/groups/${gm.group.id}/tasks/${task.taskId}`}
                    className={`${task.completedAt ? 'text-success' : 'text-accent-deep-grey'}`}
                    title={task.name}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {task.completedAt ? (
                      <SanyasIcon name="checkCircle" size="lg" />
                    ) : (
                      <SanyasIcon
                        name="circle"
                        size="lg"
                        style={{ paddingBottom: 1, paddingLeft: 2 }} // magically aligns the circle icon with the check icon
                      />
                    )}
                  </a>
                ))}
              </p>
            </div>
          );
        })}
      </div>

      {completedUserTasks.length > 0 && (
      <>
        <p>
          Total active time:
          <span className="ms-1 fw-semibold">
            {formatSeconds(totalActiveTime)}
          </span>
        </p>

        <table className="table table-striped mt-4">
          <thead>
            <tr>
              <th>Date</th>
              <th>Module</th>
              <th>Task</th>
              <th>Active time</th>
              <th>Last viewed</th>
            </tr>
          </thead>
          <tbody>
            {orderedCompletedUserTasks.slice(0, sliceSize).map((task) => <TaskLogItem key={task.id} task={task} />)}
            {showFullLog && orderedCompletedUserTasks.slice(sliceSize).map((task) => <TaskLogItem key={task.id} task={task} />)}
          </tbody>
        </table>

        <Button variant="link" className="px-0 py-1" onClick={() => setShowFullLog((prev) => !prev)}>
          {showFullLog ? 'Hide' : 'View'}
          {' '}
          full log details
        </Button>
      </>
      )}
    </>
  );
}

CourseProgress.defaultProps = {
  gm: null,
};

CourseProgress.propTypes = {
  gm: GroupMembershipPropType,
};

export default CourseProgress;
