import React, { useContext, useMemo, useState, useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_STATUSES, REDUX_STATUS, REDUX_SUCCESS } from 'lib/constants';
import { ComponentPropType } from 'lib/propTypes';
import { REMOVE_PARTICIPANTS } from 'store/groupShow/actions';

const GroupShowContext = React.createContext();

function GroupShowProvider({ children }) {
  const { id: groupId, groupParticipations, status, success } = useSelector((state) => state.groupShow.group);
  const [gmIds, setGmIds] = useState([]);
  const [selectAllContext, setSelectAllContext] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const selectAllCheckboxRef = useRef();
  const dispatch = useDispatch();
  const filteredParticipants = useMemo(() => groupParticipations.filter((gm) => ACTION_STATUSES.includes(gm.completion)), [groupParticipations]);
  const inProgress = status === REDUX_STATUS.PENDING;

  const clearGmIds = useCallback(() => {
    setGmIds([]);
    setSelectAllContext(false);
    if (selectAllCheckboxRef.current) {
      selectAllCheckboxRef.current.checked = false;
      selectAllCheckboxRef.current.indeterminate = false;
    }
  }, [selectAllCheckboxRef]);

  const setGroupMembershipId = useCallback((isChecked, gmId) => {
    const checkbox = selectAllCheckboxRef?.current;
    setGmIds((prevIds) => {
      let newIds;
      if (isChecked) {
        // Add to the array if not already present
        newIds = prevIds.includes(gmId) ? prevIds : [...prevIds, gmId];
      } else {
        // Remove from the array
        newIds = prevIds.filter((id) => id !== gmId);
      }

      const allSelected = filteredParticipants.length === newIds.length;
      setSelectAllContext(allSelected);
      if (checkbox) {
        checkbox.checked = allSelected;
        checkbox.indeterminate = newIds.length > 0 && newIds.length < filteredParticipants.length;
      }

      return newIds;
    });
  }, [filteredParticipants, selectAllCheckboxRef]);

  const selectAllParticipants = useCallback((value) => {
    setGmIds(value ? filteredParticipants.map((gm) => gm.id) : []);
    setSelectAllContext(value);
  }, [filteredParticipants]);

  const removeUsersFromGroup = useCallback(() => {
    dispatch(REMOVE_PARTICIPANTS.request({ groupId, groupMembershipIds: gmIds }));
  }, [groupId, gmIds, dispatch]);

  useEffect(() => {
    if (status === REDUX_STATUS.SUCCESS) {
      clearGmIds();
    }
    if (success === REDUX_SUCCESS.REMOVED) {
      setIsRemoving(false);
    }
  }, [status, success, clearGmIds]);

  const value = useMemo(() => ({
    gmIds,
    selectAllCheckboxRef,
    selectAllContext,
    setSelectAllContext,
    setGroupMembershipId,
    removeUsersFromGroup,
    selectAllParticipants,
    isRemoving,
    setIsRemoving,
    inProgress,
  }), [gmIds, removeUsersFromGroup, selectAllCheckboxRef, selectAllContext, selectAllParticipants, setGroupMembershipId,
    isRemoving, setIsRemoving, inProgress]);

  return (
    <GroupShowContext.Provider value={value}>
      {children}
    </GroupShowContext.Provider>
  );
}

GroupShowProvider.propTypes = {
  children: ComponentPropType.isRequired,
};

export default GroupShowProvider;

export const useGroupShowContext = () => {
  const { setGroupMembershipId, removeUsersFromGroup, selectAllCheckboxRef, selectAllContext, setSelectAllContext,
    selectAllParticipants, gmIds, isRemoving, setIsRemoving, inProgress } = useContext(GroupShowContext);
  return { setGroupMembershipId,
    removeUsersFromGroup,
    selectAllCheckboxRef,
    selectAllContext,
    setSelectAllContext,
    selectAllParticipants,
    gmIds,
    isRemoving,
    setIsRemoving,
    inProgress };
};
