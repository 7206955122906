import { takeLatest, put, select, call, delay } from 'redux-saga/effects';
import _isEmpty from 'lodash/isEmpty';
import { fetchGet, fetchPatch, fetchPost, handleSimpleFetchError } from 'lib/apiHelpers';
import {
  LOAD_GROUP_MEMBERSHIPS,
  LOAD_GROUP_MEMBERSHIPS_FORM_DATA,
  LOAD_LOG_ENTRIES,
  UPDATE_CURRICULUM_ACCESS,
  REMOVE_PARTICIPANT,
  UPDATE_INSUFFICIENT_PARTICIPATION,
  UPDATE_GROUP_MEMBERSHIP,
  LOAD_JOURNAL_AND_DISCUSSION_BOARDS,
  UPDATE_MARKED_AS_INDIGENOUS,
  RESEND_IWL,
  GET_IWL_STATUS,
  LOAD_GROUP_MEMBERSHIP,
} from 'store/groupMemberships/actions';
import { UPDATE_GROUP_PARTICIPATIONS } from 'store/groupShow/actions';
import { SET_NOTIFICATION } from 'store/flashNotifications/actions';
import { LOAD_USER } from 'store/user/actions';

function* loadGroupMemberships({ payload }) {
  const state = yield select();
  if (state.groupMemberships.data?.[0]?.userId !== payload.id) {
    try {
      const { data } = yield fetchGet(`/api/users/${payload.id}/group_memberships`, {});
      yield put(LOAD_GROUP_MEMBERSHIPS.success(data, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_GROUP_MEMBERSHIPS);
    }
  } else {
    yield put(LOAD_GROUP_MEMBERSHIPS.success({}, { cached: true }));
  }
}

function* loadGroupMembership({ payload }) {
  const { groupMemberships } = yield select();
  if (groupMemberships.data?.[0]?.id !== payload.id) {
    try {
      const { data } = yield fetchGet(`/api/facilitator/group_memberships/${payload.id}`, {});
      yield put(LOAD_GROUP_MEMBERSHIP.success(data, { cached: false }));
      yield put(LOAD_USER.request(data.userId));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_GROUP_MEMBERSHIPS);
    }
  } else {
    yield put(LOAD_GROUP_MEMBERSHIP.success({}, { cached: true }));
  }
}

function* loadJournalsAndDiscussionBoards({ payload }) {
  try {
    const { data } = yield fetchGet(`/api/users/${payload.userId}/group_memberships/${payload.groupMembershipId}/discussion_board_journal_tasks`);
    yield put(LOAD_JOURNAL_AND_DISCUSSION_BOARDS.success(data, { cached: false }));
  } catch (err) {
    yield handleSimpleFetchError(err, LOAD_JOURNAL_AND_DISCUSSION_BOARDS);
  }
}

function* loadFormData() {
  const state = yield select();
  if (_isEmpty(state.groupMemberships.formData.data)) {
    try {
      const { data } = yield fetchGet('/api/users/group_memberships_form_data', {});
      yield put(LOAD_GROUP_MEMBERSHIPS_FORM_DATA.success(data, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_GROUP_MEMBERSHIPS_FORM_DATA);
    }
  } else {
    yield put(LOAD_GROUP_MEMBERSHIPS_FORM_DATA.success({}, { cached: true }));
  }
}

function* updateGroupMembership({ payload, meta }) {
  try {
    const { data } = yield fetchPatch(`/api/users/${payload.userId}/group_memberships/${payload.id}`, payload);
    yield delay(400);
    yield put(UPDATE_GROUP_MEMBERSHIP.success(data));
    yield put(SET_NOTIFICATION.action({
      message: 'Participant updated successfully.',
      type: 'success',
    }));
  } catch (err) {
    yield handleSimpleFetchError(err, UPDATE_GROUP_MEMBERSHIP);
  } finally {
    yield call(meta.formikActions.setSubmitting, false);
  }
}

function* updateCurriculumAccess({ payload }) {
  try {
    const { data } = yield fetchPatch(`/api/users/${payload.userId}/group_memberships/${payload.id}/update_reviewer_access`, payload);
    yield delay(400);
    yield put(UPDATE_CURRICULUM_ACCESS.success(data));
    yield put(SET_NOTIFICATION.action({
      message: 'Curriculum reviewer access updated successfully.',
      type: 'success',
    }));
  } catch (err) {
    yield handleSimpleFetchError(err, UPDATE_CURRICULUM_ACCESS);
  }
}
function* removeParticipant({ payload }) {
  try {
    const { data } = yield fetchPost(`/api/users/${payload.userId}/group_memberships/${payload.id}/terminate`, payload);
    yield delay(400);
    yield put(REMOVE_PARTICIPANT.success(data));
    yield put(UPDATE_GROUP_PARTICIPATIONS.action(data));
  } catch (err) {
    yield handleSimpleFetchError(err, REMOVE_PARTICIPANT);
  }
}

function* updateInsufficientParticipation({ payload }) {
  try {
    const { data } = yield fetchPatch(`/api/users/${payload.userId}/group_memberships/${payload.id}/update_marked_as_insufficient_participation`, payload);
    yield delay(400);
    yield put(UPDATE_INSUFFICIENT_PARTICIPATION.success(data));
    yield put(SET_NOTIFICATION.action({
      message: 'Insufficient Participation status updated successfully.',
      type: 'success',
    }));
  } catch (err) {
    yield handleSimpleFetchError(err, UPDATE_INSUFFICIENT_PARTICIPATION);
  }
}

function* loadLogEntries({ payload }) {
  const { groupMemberships } = yield select();
  if (!groupMemberships.logEntries.byId || groupMemberships.logEntries.groupMembershipId !== payload.id) {
    try {
      const { data } = yield fetchGet(`/api/registrar/group_memberships/${payload.id}/log_entries`);
      yield put(LOAD_LOG_ENTRIES.success(data, { cached: false, groupMembershipId: payload.id }));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_LOG_ENTRIES);
    }
  } else {
    yield put(LOAD_LOG_ENTRIES.success({}, { cached: true }));
  }
}

function* updateMarkedAsIndigenous({ payload }) {
  let notificationMessage;
  if (payload.markedAsIndigenous) {
    notificationMessage = 'Indigenous Welcome Letter scheduled successfully.';
  } else {
    notificationMessage = 'Indigenous Welcome Letter stopped successfully.';
  }

  try {
    yield fetchPatch(`/api/users/${payload.userId}/group_memberships/${payload.id}/update_marked_as_indigenous`, payload);
    yield put(UPDATE_MARKED_AS_INDIGENOUS.success());
    yield put(SET_NOTIFICATION.action({
      message: notificationMessage,
      type: 'success',
    }));
    yield put(GET_IWL_STATUS.request({ id: payload.id, userId: payload.userId }));
  } catch (err) {
    yield handleSimpleFetchError(err, UPDATE_MARKED_AS_INDIGENOUS);
  }
}

function* resendIWL({ payload }) {
  try {
    yield fetchPost(`/api/users/${payload.userId}/group_memberships/${payload.id}/resend_iwl`, payload);
    yield put(RESEND_IWL.success());
    yield put(SET_NOTIFICATION.action({
      message: 'Indigenous Welcome Letter resent successfully.',
      type: 'success',
    }));
  } catch (err) {
    yield handleSimpleFetchError(err, RESEND_IWL);
  }
}

function* getIWLStatus({ payload }) {
  try {
    const { data } = yield fetchGet(`/api/users/${payload.userId}/group_memberships/${payload.id}/iwl_status`, payload);
    yield put(GET_IWL_STATUS.success(data));
  } catch (err) {
    yield handleSimpleFetchError(err, GET_IWL_STATUS);
  }
}

export default function* sagas() {
  yield takeLatest(LOAD_GROUP_MEMBERSHIPS.REQUEST, loadGroupMemberships);
  yield takeLatest(LOAD_GROUP_MEMBERSHIP.REQUEST, loadGroupMembership);
  yield takeLatest(LOAD_GROUP_MEMBERSHIPS_FORM_DATA.REQUEST, loadFormData);
  yield takeLatest(UPDATE_GROUP_MEMBERSHIP.REQUEST, updateGroupMembership);
  yield takeLatest(UPDATE_CURRICULUM_ACCESS.REQUEST, updateCurriculumAccess);
  yield takeLatest(REMOVE_PARTICIPANT.REQUEST, removeParticipant);
  yield takeLatest(UPDATE_INSUFFICIENT_PARTICIPATION.REQUEST, updateInsufficientParticipation);
  yield takeLatest(LOAD_LOG_ENTRIES.REQUEST, loadLogEntries);
  yield takeLatest(UPDATE_MARKED_AS_INDIGENOUS.REQUEST, updateMarkedAsIndigenous);
  yield takeLatest(RESEND_IWL.REQUEST, resendIWL);
  yield takeLatest(GET_IWL_STATUS.REQUEST, getIWLStatus);
  yield takeLatest(LOAD_JOURNAL_AND_DISCUSSION_BOARDS.REQUEST, loadJournalsAndDiscussionBoards);
}
