import React from 'react';
import PropTypes from 'prop-types';
import { StylePropType } from 'lib/propTypes';
import Icons from './icons';

const SIZES = {
  '2xs': '0.625em',
  xs: '0.75em',
  sm: '0.875em',
  md: '1em',
  lg: '1.25em',
  xl: '1.5em',
  '2xl': '2em',
  '1x': '1em',
  '2x': '2em',
  '3x': '3em',
  '4x': '4em',
  '5x': '5em',
  '6x': '6em',
  '7x': '7em',
  '8x': '8em',
  '9x': '9em',
  '10x': '10em',
};

function SanyasIcon(props) {
  const { name, className, size, style } = props;
  const IconComponent = Icons[name];

  const iconStyle = {
    lineHeight: 1,
    top: '2px',
    width: SIZES[size],
    ...style,
  };

  return (
    <span className={`position-relative d-inline-block flex-shrink-0 ${className || ''}`} style={iconStyle}>
      <IconComponent />
    </span>
  );
}

SanyasIcon.defaultProps = {
  className: undefined,
  size: 'md',
  style: null,
};

SanyasIcon.propTypes = {
  name: PropTypes.oneOf(Object.keys(Icons)).isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(SIZES)),
  style: StylePropType,
};

export { SIZES as ICON_SIZES };
export default SanyasIcon;
