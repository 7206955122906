import React from 'react';
import { useWorkspace } from 'lib/hooks/useWorkspace';
import { useGroupShowContext } from 'pages/registrar/context';
import { Button, Dropdown, DropdownButton, Spinner } from 'react-bootstrap';
import DefaultModal from 'components/shared/Modal/DefaultModal';

function ParticipantActionRow() {
  const { id: workspaceId } = useWorkspace();
  const { removeUsersFromGroup, selectAllCheckboxRef, selectAllContext, selectAllParticipants, gmIds,
    isRemoving,
    setIsRemoving, inProgress } = useGroupShowContext();

  return (
    <div className="d-flex align-items-center ms-3 mb-2">
      <input
        ref={selectAllCheckboxRef}
        name="select-all"
        type="checkbox"
        className="form-check-input me-2"
        aria-label="Select all participants"
        checked={selectAllContext}
        onChange={({ target }) => selectAllParticipants(target.checked)}
      />
      <DropdownButton size="sm" variant="outline-secondary" title="Actions">
        {workspaceId === 'registrar' && (
        <Dropdown.Item onClick={() => setIsRemoving(true)} disabled={!gmIds?.length}>
          Remove
        </Dropdown.Item>

        )}
      </DropdownButton>

      <DefaultModal
        isOpen={isRemoving}
        onClose={() => setIsRemoving(false)}
        header="Remove from group"
        footerComponent={(
          <Button variant="primary" onClick={removeUsersFromGroup} disabled={inProgress}>
            Remove
            {inProgress && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
          </Button>
        )}
      >
        {`Are you sure you want to remove ${gmIds.length} participant${gmIds.length !== 1 ? 's' : ''}?`}
        <br />
        Removing them will also remove their content from this group.
      </DefaultModal>
    </div>

  );
}

export default ParticipantActionRow;
