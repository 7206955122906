import { takeLatest, put, select, call, delay } from 'redux-saga/effects';
import { fetchGet, fetchPost, handleSimpleFetchError, fetchPatch, fetchDelete, fetchPut } from 'lib/apiHelpers';
import { LOAD_FACILITATOR_COURSE_TYPES } from 'store/courseTypes/actions';
import { SET_NOTIFICATION } from 'store/flashNotifications/actions';
import { CREATE_COMMENT, CREATE_REPLY_COMMENT, LOAD_COMMENTS, LOAD_PARTICIPANT, LOAD_PARTICIPANT_IWL_STATUS,
  UPDATE_COMMENT, MARK_NOTIFICATION_AS_READ, MARK_NOTIFICATION_AS_UNREAD, DELETE_COMMENT, UPDATE_COMMENT_FLAG,
  CREATE_FACILITATOR_NOTE, UPDATE_FACILITATOR_NOTE, LOAD_JOURNAL_ANSWERS,
  GET_FACILITATOR_LIVE_FEED, LOAD_PARTICIPANT_JOURNAL, FLAG_JOURNAL,
  GET_SUB_GROUP_DATA, RESET_DASHBOARD_STATUS } from 'store/facilitatorDashboard/actions';
import { CARD_TYPES } from 'store/facilitatorDashboard/reducer';

export default function* sagas() {
  yield takeLatest(LOAD_FACILITATOR_COURSE_TYPES.REQUEST, function* loadCourseTypes() {
    const state = yield select();
    if (!state.courseTypes.allIds.length) {
      try {
        const { data } = yield fetchGet('/api/facilitator/course_types', {});
        yield put(LOAD_FACILITATOR_COURSE_TYPES.success(data, { cached: false }));
      } catch (err) {
        yield handleSimpleFetchError(err, LOAD_FACILITATOR_COURSE_TYPES);
      }
    } else {
      yield put(LOAD_FACILITATOR_COURSE_TYPES.success({}, { cached: true }));
    }
  });

  yield takeLatest(GET_SUB_GROUP_DATA.REQUEST, function* getSubGroupData({ payload }) {
    const { subGroupId } = payload;
    try {
      const data = yield fetchGet('/api/facilitator/dashboard/sub_group_data', { subGroupId });
      yield put(GET_SUB_GROUP_DATA.success(data, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, GET_SUB_GROUP_DATA);
    }
  });

  yield takeLatest(LOAD_COMMENTS.REQUEST, function* loadComments({ payload }) {
    const { id, subGroupId, taskId } = payload;
    try {
      const { data } = yield fetchGet('/api/facilitator/discussion_tasks/comments', { groupId: id, subGroupId, taskId });
      yield put(LOAD_COMMENTS.success(data, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_COMMENTS);
    }
  });

  yield takeLatest(CREATE_REPLY_COMMENT.REQUEST, function* saveComment({ payload, meta }) {
    try {
      const { comment } = yield fetchPost('/api/facilitator/comments/', payload);
      yield delay(400);
      yield put(CREATE_REPLY_COMMENT.success({ comment }, { cached: false }));
      if (meta?.actions?.handleClose) {
        yield call(meta.actions.handleClose);
      }
    } catch (err) {
      yield handleSimpleFetchError(err, CREATE_REPLY_COMMENT);
      if (meta?.actions?.setSubmitting) {
        yield call(meta.actions.setSubmitting, false);
      }
    }
  });

  yield takeLatest(CREATE_COMMENT.REQUEST, function* saveComment({ payload, meta }) {
    try {
      const comment = yield fetchPost('/api/facilitator/comments/', payload);
      yield delay(400);
      yield put(CREATE_COMMENT.success(comment, { cached: false }));
      if (meta?.actions?.handleClose) {
        yield call(meta.actions.handleClose);
      }

      if ((meta && !meta.answer?.notificationCleared) && comment.comment.taskType === 'JournalTask') {
        yield put(MARK_NOTIFICATION_AS_READ.request(
          { id: meta.answer.notificationId, subGroupId: comment.comment.subGroupId }, // payload
          { cardType: CARD_TYPES.ANSWER_CARD, priority: meta.answer.notificationPriority, gmId: meta.answer.participationId, cached: false }, // meta
        ));
      }

      if (meta?.actions?.setSubmitting) {
        yield call(meta.actions.setSubmitting, false);
      }
      if (meta?.actions?.resetForm) {
        yield call(meta.actions.resetForm);
      }
    } catch (err) {
      yield handleSimpleFetchError(err, CREATE_COMMENT);
      if (meta?.actions?.setSubmitting) {
        yield call(meta.actions.setSubmitting, false);
      }
    }
  });

  yield takeLatest(UPDATE_COMMENT.REQUEST, function* updateComment({ payload }) {
    try {
      const comment = yield fetchPatch(`/api/facilitator/comments/${payload.updatedComment.id}`, payload.updatedComment);
      yield put(UPDATE_COMMENT.success(comment, { cached: false }));
      yield put(RESET_DASHBOARD_STATUS.action());
    } catch (err) {
      yield handleSimpleFetchError(err, UPDATE_COMMENT);
    }
  });

  yield takeLatest(LOAD_JOURNAL_ANSWERS.REQUEST, function* loadComments({ payload }) {
    const { id, subGroupId, taskId } = payload;
    try {
      const { data } = yield fetchGet('/api/facilitator/journal_tasks/answers', { groupId: id, subGroupId, taskId });
      yield put(LOAD_JOURNAL_ANSWERS.success(data, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_COMMENTS);
    }
  });

  yield takeLatest(MARK_NOTIFICATION_AS_READ.REQUEST, function* updateComment({ payload, meta }) {
    try {
      const { data } = yield fetchPatch(`/api/facilitator/notifications/${payload.id}/mark_as_read`, payload);
      yield put(MARK_NOTIFICATION_AS_READ.success(data, { ...meta, cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, UPDATE_COMMENT);
    }
  });

  yield takeLatest(MARK_NOTIFICATION_AS_UNREAD.REQUEST, function* updateComment({ payload, meta }) {
    try {
      const { data } = yield fetchPatch(`/api/facilitator/notifications/${payload.id}/mark_as_unread`, payload);
      yield put(MARK_NOTIFICATION_AS_UNREAD.success(data, { ...meta, cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, UPDATE_COMMENT);
    }
  });

  yield takeLatest(LOAD_PARTICIPANT.REQUEST, function* loadParticipant({ payload }) {
    const { facilitatorDashboard } = yield select();
    if (facilitatorDashboard.participants.allIds.includes(payload)) {
      yield put(LOAD_PARTICIPANT.success({}, { cached: true }));
    } else {
      try {
        const { data } = yield fetchGet(`/api/facilitator/group_memberships/${payload}`, { participantOnly: true });
        yield put(LOAD_PARTICIPANT.success(data, { cached: false }));

        if (data.isIndigenous) {
          yield put(LOAD_PARTICIPANT_IWL_STATUS.request({ id: data.id, userId: data.userId }));
        }
      } catch (err) {
        yield handleSimpleFetchError(err, LOAD_PARTICIPANT, { id: payload });
      }
    }
  });

  yield takeLatest(LOAD_PARTICIPANT_IWL_STATUS.REQUEST, function* getIWLStatus({ payload }) {
    try {
      const { data } = yield fetchGet(`/api/users/${payload.userId}/group_memberships/${payload.id}/iwl_status`);
      yield put(LOAD_PARTICIPANT_IWL_STATUS.success(data));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_PARTICIPANT_IWL_STATUS);
    }
  });

  yield takeLatest(DELETE_COMMENT.REQUEST, function* deleteComment({ payload }) {
    try {
      const { commentId } = yield fetchDelete(`/api/facilitator/comments/${payload.id}`, payload);
      yield put(DELETE_COMMENT.success(commentId));
    } catch (err) {
      yield handleSimpleFetchError(err, DELETE_COMMENT);
    }
  });

  yield takeLatest(UPDATE_COMMENT_FLAG.REQUEST, function* updateCommentFlag({ payload, meta }) {
    try {
      const comment = yield fetchPatch(`/api/facilitator/comments/${payload.id}/flag`, payload);
      yield put(UPDATE_COMMENT.success(comment));
      yield put(RESET_DASHBOARD_STATUS.action());
    } catch (err) {
      yield handleSimpleFetchError(err, UPDATE_COMMENT);
    } finally {
      if (meta?.formikActions) {
        yield call(meta.formikActions.setSubmitting, false);
      }
    }
  });

  yield takeLatest(CREATE_FACILITATOR_NOTE.REQUEST, function* createNote({ payload, meta }) {
    try {
      const { data } = yield fetchPost('/api/facilitator/facilitator_notes', payload);
      yield put(CREATE_FACILITATOR_NOTE.success(data, { cached: false }));
    } catch (err) {
      yield put(SET_NOTIFICATION.action({
        message: 'There was an error creating the note.',
        type: 'error',
      }));
    } finally {
      if (meta?.formikActions) {
        yield call(meta.formikActions.setSubmitting, false);
      }
    }
  });

  yield takeLatest(UPDATE_FACILITATOR_NOTE.REQUEST, function* updateNote({ payload, meta }) {
    try {
      const { data } = yield fetchPatch(`/api/facilitator/facilitator_notes/${payload.id}`, payload);
      yield put(UPDATE_FACILITATOR_NOTE.success(data, { cached: false }));
    } catch (err) {
      yield put(SET_NOTIFICATION.action({
        message: 'There was an error saving the note.',
        type: 'error',
      }));
    } finally {
      if (meta?.formikActions) {
        yield call(meta.formikActions.setSubmitting, false);
      }
    }
  });

  yield takeLatest(GET_FACILITATOR_LIVE_FEED.REQUEST, function* getLiveFeed({ payload }) {
    const { feedType, taskType } = payload;
    try {
      const data = yield fetchGet('/api/facilitator/notifications/live_feed', { feedType, taskType });
      yield put(GET_FACILITATOR_LIVE_FEED.success(data));
    } catch (err) {
      yield handleSimpleFetchError(err, GET_FACILITATOR_LIVE_FEED);
    }
  });

  yield takeLatest(LOAD_PARTICIPANT_JOURNAL.REQUEST, function* loadComments({ payload }) {
    const { id, subGroupId, taskId, groupMembershipId } = payload;
    try {
      const { data } = yield fetchGet('/api/facilitator/journal_tasks/participant_journal', { groupId: id, subGroupId, taskId, groupMembershipId });
      yield put(LOAD_PARTICIPANT_JOURNAL.success(data, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_PARTICIPANT_JOURNAL);
    }
  });

  yield takeLatest(FLAG_JOURNAL.REQUEST, function* flagJournal({ payload, meta }) {
    try {
      const { userTaskId, subGroupId, taskId, gmId } = payload;
      const { data } = yield fetchPut(`/api/facilitator/journal_tasks/update_flag/${userTaskId}`, payload);
      yield delay(400);
      yield put(FLAG_JOURNAL.success({ ...data, subGroupId, taskId, gmId }));
    } catch (err) {
      yield handleSimpleFetchError(err, FLAG_JOURNAL);
    } finally {
      yield call(meta.formikActions.setSubmitting, false);
    }
  });
}
